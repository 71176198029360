:root
{
	--comman-radious :3px;
	--comman-padding: 25px 40px;
}	

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Rubik', sans-serif;
}
.main{
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #dbdbe6;
}
.main-card
{
	max-width:440px;
	width: 100%;
	border-radius: 20px;
	background-color: #fff;
	/*padding: 50px;*/
}

#recaptcha {
    margin-top: 20px;
    margin-left: 6%;
}
.card-top
{
	display: flex;
	min-height: 100px;
	justify-content: space-between;
	flex-direction: column;
	padding: var(--comman-padding);
}
.card-bottom
{
	background-color: #f6f6fb;
	display: flex;
	flex-direction: column;
	padding: var(--comman-padding);
	border-radius: 0 0 20px 20px;
}
.card-top h3,p
{
 padding-top: 15px;
}
.card-top h3
{
 font-size:1.3rem;
}
.card-top p{
	font-size:0.7rem;
}
.neoistone-input
{
	margin-top:15px;
	width:100%;
	padding: 16px;
	border: 1px solid #eee;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: var(--comman-radious);
}
.neoistone-input .option
{
	font-size: 0.8rem;
}

.card-top h3,.sign-as,h4,input,.btn-grp
{
 margin-top: 15px;
}
.card-bottom h3
{
	font-size: 0.9rem;
}
.card-bottom h4
{
	font-size: 0.9rem;
	justify-content: center;
    margin-left: -20px;
}
.neoistone-grid {
	display: grid;
	grid: 50px / auto auto auto;
	justify-content: space-between;
	align-items: center;
}
.social
{
	width:33%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border: 1px solid #eee;
	background-color: #fff;
	padding: 10px 15px;
	border-radius: var(--comman-radious);
	width:104px;
	cursor: pointer;
	transition: 0.3s;
	margin-bottom: 10px;
	margin-right: 4px;

}
.social:hover
{
	transform: scale(1.1);
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}
.social .logo img
{
 width: 20px;
 height: 20px;
 object-fit: cover;
}
.social span{
	font-size: 0.7rem;
	padding-left: 5px;
}

.card-top .btn-grp
{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.card-top .btn-grp button {
	width: 100%;
	padding:17px 20px;
	cursor: pointer;
	font-size: 0.7rem;
	background-color: #1d1d1f;
	border: none;
	color: #fff;
	transition: 0.5s;
}

.card-top .btn-grp input[type="submit"]{
	width: 100%;
	padding:17px 20px;
	cursor: pointer;
	font-size: 0.7rem;
	background-color: #1d1d1f;
	border: none;
	color: #fff;
	transition: 0.5s;
}

.copyright {
    font-size: .7rem;
}
.neoistone_new {
  display: grid;
  justify-content: space-between;
  grid: 50px / auto auto auto;
}

.cred {
    color: red;
}

.bred {
    background: red;
}

.cblue {
     color: blue;
}

.bblue {
    background : blue;
}

.message
{
    font-size: 0.8rem;
    margin-top: 8px;
    margin-left: 4px
}

.neoistone_error 
{
    border: 1px solid red;
}

.iti--allow-dropdown
{
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--comman-radious);
}

#loader-wrapper {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000008c;
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  z-index: 1000;
}
#loader:after,
#loader:before {
  content: "";
  position: absolute;
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
#loader {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: 2s linear infinite spin;
  animation: 2s linear infinite spin;
  z-index: 1001;
}
#loader:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: 3s linear infinite spin;
  animation: 3s linear infinite spin;
}
#loader:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: 1.5s linear infinite spin;
  animation: 1.5s linear infinite spin;
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transition: 0.3s ease-out 0.3s;
  transition: 0.3s ease-out 0.3s;
}
.no-js #loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #222;
}

.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.2em;
}

.not-found a {
  text-decoration: none;
  color: #007bff;
}

.not-found a:hover {
  text-decoration: underline;
}

.recaptcha 
{
  margin-top: 20px
}

.recaptcha iframe {
  margin-left: 6%;
}